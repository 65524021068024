import React, { useState, useEffect } from "react";
import { db } from "../../utils/firebase";
import { onValue, ref, query, orderByKey, startAt, endAt, limitToLast } from 'firebase/database';
import { commify } from "../../Helper";
import { GfGWeatherApp, VolTankCard } from "../../components";

import FirebaseDB from "../../config/FirebaseDB";

const vol_tank = FirebaseDB.VolTank;

const TangkiAir = () => {
    const [dataVolTank, setDataVolTank] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);

    useEffect(() => {
        queryVolTank();
        return () => {
            setDataVolTank([]);
        };
    }, []);

    useEffect(() => {
        function handleValue() {
            var totalVolume = 0;
            Object.keys(dataVolTank).map((key) => {
                totalVolume += dataVolTank[key]['volume'];
            })
            setDataTotal(totalVolume);
        }
        handleValue();
        return () => {
            setDataTotal(0);
        };
    }, [dataVolTank]);

    const queryVolTank = () => {
        const query = ref(db, vol_tank);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (snapshot.exists()) {
                //vol_tank
                setDataVolTank(data);
            }
        });
    }

    return (
        <div class="container-fluid p-3">
            <h1 class="text-center text-dark fw-bold lh-1" style={{ letterSpacing: '3px' }}>Tangki Air</h1>
            <div className="Header-Container">
                <GfGWeatherApp />
                <div class="text-end text-dark align-items-center row">
                    <div>Total<div class="fw-bold lh-1" style={{ fontSize: 70, margin: 0 }}>{commify(parseInt(dataTotal))}<span style={{ fontWeight: 'normal', fontSize: 40 }}>Liter</span></div></div>
                </div>
            </div>

            <div class="row pb-2">
                {Object.keys(dataVolTank).map((key) => {
                    return (
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3 pb-2">
                            <VolTankCard data={dataVolTank[key]} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TangkiAir;
