import React, { useEffect, useState } from "react";
import { AreaChartBasic } from "../AreaCharts";
import { FaSyncAlt, FaWifi } from 'react-icons/fa';
import { commify, convertEpoch } from "../../Helper";
import { ColumnChartsGroupLabel } from "../ColumnCharts";
import { Card } from "../../atoms";

function RiwayatMesin({ dataHPD1, dataHPD2, toggleParent }) {
    const [toggle, setToggle] = useState(true);
    const [dataHPDT, setDataHPDT] = useState([]);

    useEffect(() => {
        function handleValue() {
            setToggle(toggleParent);
        }
        handleValue();
        return () => {
            setToggle(true);
        };
    }, [toggleParent]);

    useEffect(() => {
        function handleValue() {
            queryPackDusTotal(dataHPD1, dataHPD2);
        }
        handleValue();
        return () => {
            setDataHPDT([]);
        };
    }, [dataHPD1, dataHPD2]);

    function chageView() {
        setToggle(toggle => !toggle);
    }

    const queryPackDusTotal = (HPD1, HPD2) => {
        var array = [...HPD1, ...HPD2];

        var result = [];
        array.reduce(function (res, value) {
            if (!res[value.label]) {
                res[value.label] = { label: value.label, data: 0 };
                result.push(res[value.label])
            }
            res[value.label].data += value.data;
            return res;
        }, {});

        setDataHPDT(result.sort((a, b) => a.label > b.label ? 1 : -1));
    }

    return (
        <div class="card h-100 text-center border-0">
            <div className="Button-Absolute" onClick={chageView}>
                <FaSyncAlt class="text-dark bg-white p-1" style={{ height: 20, width: 20 }} />
            </div>
            {toggle ? (<RiwayatView dataHPD1={dataHPD1} dataHPD2={dataHPD2} />) : (<TotalRiwayatView dataHPDT={dataHPDT} />)}
        </div>
    );
}

function RiwayatView({ dataHPD1, dataHPD2 }) {
    return (
        <div class="row pb-2">
            <div class="col-12 col-md-6 pb-2">
                <Card title={'Riwayat Mesin 2'}>
                    <ColumnChartsGroupLabel value={dataHPD2} height={350} />
                </Card>
            </div>
            <div class="col-12 col-md-6 pb-2">
                <Card title={'Riwayat Mesin 1'}>
                    <ColumnChartsGroupLabel value={dataHPD1} height={350} />
                </Card>
            </div>
        </div>
    );
}

function TotalRiwayatView({ dataHPDT }) {
    return (
        <div class="row pb-2">
            <div class="col-12 pb-2">
                <Card title={'Riwayat Mesin Total'}>
                    <ColumnChartsGroupLabel value={dataHPDT} height={350} />
                </Card>
            </div>
        </div>
    );
}

export default RiwayatMesin;
