import React from "react";

const Card = ({ title, children }) => {
    return (
        <div class="card h-100 text-center border-light-subtle" >
            <div class="card-body">
                <h5 class="card-title">{title}</h5>
                {children}
            </div>
        </div>
    );
};

export default Card;
