import { useEffect, useState } from "react";
import { ProgressLine } from "../../atoms";

const style = {
    width: 100,
    display: 'inline-block',
};

const Gauge = ({ value }) => {
    const [color, setColor] = useState('');
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        function handleValue() {
            setPercent(value);

            if (value <= 40) {
                setColor('#5aff07');
            } else if (value <= 70) {
                setColor('#ffd814');
            } else if (value <= 100) {
                setColor('#ff2121');
            }
        }
        handleValue();
        return () => {
            setColor('');
            setPercent(0);
        };
    }, [value]);

    return (
        <div style={style}>
            <ProgressLine percent={percent} color={color} vertical={true} />
        </div>
    );
}

export default Gauge;