import React from "react";
import {
    Routes,
    Route,
} from "react-router-dom";
import { PackDus, TangkiAir, IPAL } from "../pages";
import Page from "../Page";

function Routers() {
    return (
        <Routes>
            <Route exact path='/'
                element={
                    <Page title="Packing Press"><PackDus /></Page>
                }
            />
            <Route exact path='/tangki-air'
                element={
                    <Page title="Tangki Air"><TangkiAir /></Page>
                }
            />
            <Route exact path='/ipal'
                element={
                    <Page title="IPAL"><IPAL /></Page>
                }
            />
        </Routes>
    );
}

export default Routers;