import React, { useEffect, useState } from "react";
import { ProgressLine } from "..";
import { AreaChartBasic } from "../AreaCharts";
import { FaSyncAlt } from 'react-icons/fa';
import Gauge from "./Gauge";

function KualitasUdaraCard({ value, log, toggleParent }) {
    const [toggle, setToggle] = useState(true);

    useEffect(() => {
        function handleValue() {
            setToggle(toggleParent);
        }
        handleValue();
        return () => {
            setToggle(true);
        };
    }, [toggleParent]);

    function chageView() {
        setToggle(toggle => !toggle);
    }

    return (
        <div class="card h-100 text-center border-light-subtle">
            <div className="Button-Absolute" onClick={chageView}>
                <FaSyncAlt class="text-dark bg-white p-1" style={{ height: 20, width: 20 }} />
            </div>
            {toggle ? (<GaugeView value={value} />) : (<ChartView log={log} />)}
        </div>
    );
}

function GaugeView({ value }) {
    return (
        <div class="card-body">
            <h5 class="card-title">Kualitas Udara</h5>
            <div class="d-flex justify-content-center">
                <Gauge value={value} />
                <p className="Text-Sensor">{parseInt(value)}<sup style={{ fontSize: 30 }}>PPM</sup></p>
            </div>
        </div>
    );
}

function ChartView({ log }) {
    return (
        <div>
            <div className="Text-Absolute"><h5 class="card-title">Kualitas Udara</h5></div>
            <AreaChartBasic title={''} subtitle={''} value={log} height={200} />
        </div>
    );
}

export default KualitasUdaraCard;
