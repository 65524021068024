import React from "react";

import './App.css';
import Router from "./router";
import { BrowserRouter } from "react-router-dom";
import SideMenu from "./router/SideMenu";

function App() {
  return (
    <BrowserRouter>
      <SideMenu />
      <Router />
    </BrowserRouter>
  );
}

export default App;
