import React, { useState, useEffect } from "react";
import { LineProgressBar } from '@frogress/line'
import styled from 'styled-components'

const ProgressBar = ({ props }) => {
    const { target } = props;
    const { value } = props;
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        function handleValue() {
            setPercent(value / target * 100);
        }
        handleValue();
        return () => {
            setPercent(0);
        };
    }, [target, value]);

    return (
        <LineProgressBar
            percent={percent}
            height={36}
            rounded={36}
            progressColor="linear-gradient(to right, #00FF15, #ffd814, #FF2121)"
            label={(value) => <Label>{`${parseFloat(value).toFixed(2)}%`}</Label>}
        />
    );
};

const Label = styled.span`
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: white;
`

export default ProgressBar;
