import React, { useEffect, useState } from "react";
import { AreaChartBasic } from "../AreaCharts";
import { FaSyncAlt, FaWifi } from 'react-icons/fa';
import { commify, convertEpoch } from "../../Helper";
import Gauge from "./Gauge";

function HitungMesin({ title, value, target, lastboot, log, toggleParent, wifi }) {
    const [toggle, setToggle] = useState(true);

    useEffect(() => {
        function handleValue() {
            setToggle(toggleParent);
        }
        handleValue();
        return () => {
            setToggle(true);
        };
    }, [toggleParent]);

    function chageView() {
        setToggle(toggle => !toggle);
    }

    return (
        <div class="card h-100 text-center border-light-subtle">
            <div class="d-flex flex-column justify-content-around">
                <span class="badge bg-secondary"><FaWifi /> {wifi.ssid} {wifi.ip} {wifi.signal_strength}dBm</span>
            </div>
            <div className="Button-Absolute" onClick={chageView}>
                <FaSyncAlt class="text-dark bg-white p-1" style={{ height: 20, width: 20 }} />
            </div>
            {toggle ? (<GaugeView title={title} value={value} target={target} lastboot={lastboot} />) : (<ChartView title={title} log={log} />)}
        </div>
    );
}

function GaugeView({ title, value, target, lastboot }) {
    return (
        <div class="card-body">
            <h5 class="card-title">{title}</h5>
            <div style={{ minHeight: 350 }} class="d-flex flex-column justify-content-around">
                <p className="Text-Counter">{commify(value.hitung)}</p>
                <Gauge props={{ value: value.hitung, target: target }} />
                <div class="d-flex justify-content-around">
                    <h6><span class="badge bg-secondary">Data terakhir</span> {convertEpoch(value.timestamp)}</h6>
                    <h6><span class="badge bg-secondary">Boot terakhir</span> {lastboot}</h6>
                </div>
            </div>
        </div>
    );
}

function ChartView({ title, log }) {
    return (
        <div class="card-body">
            <h5 class="card-title">Pergerakan {title}</h5>
            <AreaChartBasic title={''} subtitle={''} value={log} height={350} />
        </div>
    );
}

export default HitungMesin;
