import React from 'react';
import { slide as Menu } from 'react-burger-menu';

var styles = {
    bmBurgerButton: {
        position: 'fixed',
        width: '26px',
        height: '20px',
        left: '15px',
        top: '15px'
    },
    bmBurgerBars: {
        background: '#373a47'
    },
    bmBurgerBarsHover: {
        background: '#a90000'
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%'
    },
    bmMenu: {
        background: '#373a47',
        padding: '2.5em 1.5em 0',
        fontSize: '1.15em'
    },
    bmMorphShape: {
        fill: '#373a47'
    },
    bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em'
    },
    bmItem: {
        display: 'inline-block',
        padding: 10,
        color: '#FFFFFF',
        textDecoration: 'none',
        width: '100%'
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)'
    }
}

const SideMenu = () => {
    return (
        <Menu styles={styles}>
            <a id="packing-press" href="/">Packing Press</a>
            <a id="tangki-air" href="/tangki-air">Tangki Air</a>
            <a id="ipal" href="/ipal">IPAL</a>
        </Menu >
    );
}

export default SideMenu;
