import React, { useState, useEffect } from "react";
import { ProgressBar } from "../../atoms";

const Gauge = ({ props }) => {
    const { target } = props;
    const { value } = props;
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        function handleValue() {
            setPercent(value / target * 100);
        }
        handleValue();
        return () => {
            setPercent(0);
        };
    }, [target, value]);

    return (
        <ProgressBar percent={percent} />
    );
};

export default Gauge;
