import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const AreaChartBasic = ({ title, subtitle, value, height }) => {
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        function handleValue() {
            let dataArr = [];
            let labelArr = [];
            value.map(item => {
                dataArr.push(item.data);
                labelArr.push(item.label);
            });
            setData(dataArr);
            setLabels(labelArr);
        }
        handleValue();
        return () => {
            setData([]);
            setLabels([]);
        };
    }, [value]);

    return (
        <ReactApexChart options={{
            chart: {
                type: 'area',
                height: { height },
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },

            title: {
                text: title,
                align: 'left',
            },
            subtitle: {
                text: subtitle,
                align: 'left'
            },
            labels: labels,
            xaxis: {
                type: 'string',
            },
            yaxis: {
                opposite: true
            },
            legend: {
                horizontalAlign: 'left'
            }
        }} series={[{
            name: "",
            data: data
        }]} type="area" height={height} style={{ marginBottom: -20, marginTop: -20 }} />
    );
}

export default AreaChartBasic;
