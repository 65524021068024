import { useEffect, useState, useLayoutEffect, useRef } from "react";
import { LiquidGauge } from "../LiquidGauge";

const Gauge = ({ value, target }) => {
    const elementRef = useRef(null);
    const [elementWidth, setElementWidth] = useState(0);
    const [elementHeight, setElementHeight] = useState(0);

    const [percent, setPercent] = useState(0);
    const [radius, setRadius] = useState(200);

    useLayoutEffect(() => {
        function handleResize() {
            if (elementRef.current) {
                const width = elementRef.current.offsetWidth;
                const height = elementRef.current.offsetHeight;
                setElementWidth(width);
                setElementHeight(height);

                if (width > 395) {
                    setRadius(200);
                }
                if (width <= 395) {
                    setRadius(150);
                }
                if (width <= 300) {
                    setRadius(100);
                }
            }
        }

        handleResize(); // initial call to get width and height of the element
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [elementRef]);

    useEffect(() => {
        function handleValue() {
            setPercent(value / target * 100);
            // setPercent(value);
        }
        handleValue();
        return () => {
            setPercent(0);
        };
    }, [value]);

    return (
        <div ref={elementRef}>
            <LiquidGauge value={percent}
                radius={radius}
            />
        </div>
    );
}

export default Gauge;