import { Oval } from 'react-loader-spinner';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { showTime } from '../../Helper';
// import './App.css';

function GfGWeatherApp() {
    const [time, setTime] = useState(new Date());
    const [weather, setWeather] = useState({
        loading: false,
        data: {},
        error: false,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setupWeather();
    }, []);

    const setupWeather = async () => {
        setWeather({ ...weather, loading: true });
        const url = 'https://api.openweathermap.org/data/2.5/weather';
        const api_key = 'f00c38e0279b7bc85480c3fe775d518c';
        await axios
            .get(url, {
                params: {
                    q: 'cirebon',
                    units: 'metric',
                    appid: api_key,
                },
            })
            .then((res) => {
                // console.log('res', res);
                setWeather({ data: res.data, loading: false, error: false });
            })
            .catch((error) => {
                setWeather({ ...weather, data: {}, error: true });
                // console.log('error', error);
            });
    }

    const toDateFunction = () => {
        const months = [
            'Januari',
            'Februari',
            'Maret',
            'April',
            'Mei',
            'Juni',
            'Juli',
            'Agustus',
            'September',
            'Oktober',
            'November',
            'Desember',
        ];
        const WeekDays = [
            'Ahad',
            'Senin',
            'Selasa',
            'Rabu',
            'Kamis',
            `Jum'at`,
            'Sabtu',
        ];
        const currentDate = new Date();
        const date = `${WeekDays[currentDate.getDay()]}, ${currentDate.getDate()} ${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
        return date;
    };

    return (
        <div className="App">
            {weather.loading && (
                <>
                    <br />
                    <br />
                    <Oval type="Oval" color="black" height={100} width={100} />
                </>
            )}
            {weather.error && (
                <>
                    <br />
                    <br />
                    <span className="error-message">
                        <FontAwesomeIcon icon={faFrown} />
                        <span style={{ fontSize: '20px' }}>City not found</span>
                    </span>
                </>
            )}
            {weather && weather.data && weather.data.main && (
                <div class="d-flex justify-content-center">
                    <div class="text-dark px-1">
                        <img
                            style={{ width: 90 }}
                            src={`https://openweathermap.org/img/wn/${weather.data.weather[0].icon}@2x.png`}
                            alt={weather.data.weather[0].description}
                        />
                        {Math.round(weather.data.main.temp)}
                        <sup>°C</sup>
                    </div>
                    <div class="d-flex flex-column justify-content-center text-start px-2 text-dark">
                        <strong class="lh-sm">{toDateFunction()}</strong>
                        {/* <span class="lh-sm fs-6">{weather.data.weather[0].description.toUpperCase()}</span> */}
                        <span class="lh-sm fs-1">{showTime(time)}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default GfGWeatherApp;
