import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { dateFormat } from "../../../Helper";

// import * as dayjs from 'dayjs'
// var quarterOfYear = require('dayjs/plugin/quarterOfYear')
// dayjs.extend(quarterOfYear)

const ColumnChartsGroupLabel = ({ value, height }) => {
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        function handleValue() {
            let seriesArr = [];
            let categoriesArr = [];
            value.map(item => {
                seriesArr.push(item.data);
                categoriesArr.push(dateFormat(item.label));
            });
            setSeries(seriesArr);
            setCategories(categoriesArr);
        }
        handleValue();
        return () => {
            setSeries([]);
            setCategories([]);
        };
    }, [value]);

    return (
        <ReactApexChart options={{
            chart: {
                height: height,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            xaxis: {
                categories: categories,
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            // title: {
            //     text: 'Monthly Inflation in Argentina, 2002',
            //     floating: true,
            //     offsetY: 230,
            //     align: 'center',
            //     style: {
            //         color: '#444'
            //     }
            // }
        }} series={[{ name: 'Total', data: series }]} type="bar" height={height} style={{ marginBottom: -20 }} />
    );
};

export default ColumnChartsGroupLabel;
