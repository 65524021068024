import React, { useState, useEffect } from "react";
import { db } from "../../utils/firebase";
import { onValue, ref, query, orderByKey, startAt, endAt, limitToLast } from 'firebase/database';
import { commify } from "../../Helper";
import { GfGWeatherApp, IpalPHCard } from "../../components";

import FirebaseDB from "../../config/FirebaseDB";

const ipal = FirebaseDB.ipal;

const IPAL = () => {
    const [dataIpal, setDataIpal] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);

    useEffect(() => {
        queryIpal();
        return () => {
            setDataIpal([]);
        };
    }, []);

    // useEffect(() => {
    //     function handleValue() {
    //         var totalVolume = 0;
    //         Object.keys(dataIpal).map((key) => {
    //             totalVolume += dataIpal[key]['volume'];
    //         })
    //         setDataTotal(totalVolume);
    //     }
    //     handleValue();
    //     return () => {
    //         setDataTotal(0);
    //     };
    // }, [dataIpal]);

    const queryIpal = () => {
        const query = ref(db, ipal);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (snapshot.exists()) {
                setDataIpal(data);
            }
        });
    }

    return (
        <div class="container-fluid p-3">
            <h1 class="text-center text-dark fw-bold lh-1" style={{ letterSpacing: '3px' }}>IPAL</h1>
            <div className="Header-Container">
                <GfGWeatherApp />
                <div class="text-end text-dark align-items-center row">
                    {/* <div>Total<div class="fw-bold lh-1" style={{ fontSize: 70, margin: 0 }}>{commify(parseInt(dataTotal))}<span style={{ fontWeight: 'normal', fontSize: 40 }}>Liter</span></div></div> */}
                </div>
            </div>

            <div class="row pb-2">
                {Object.keys(dataIpal).map((key) => {
                    return (
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3 pb-2">
                            <IpalPHCard data={dataIpal[key]} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default IPAL;
