import React, { useState, useEffect } from "react";
import { db } from "../../utils/firebase";
import { onValue, ref, query, orderByKey, startAt, endAt, limitToLast } from 'firebase/database';
import { Card } from "../../atoms";
import { commify, formatDate } from "../../Helper";
import { GfGWeatherApp, TemperaturCard, KelembabanCard, KualitasUdaraCard, HitungMesin, RiwayatMesin } from "../../components";
import { ColumnChartsGroupLabel } from "../../components/ColumnCharts";
import { FaSyncAlt } from 'react-icons/fa';

import FirebaseDB from "../../config/FirebaseDB";

let dateEnd = new Date();

const pack_dus_1 = FirebaseDB.PackDus1;
const pack_dus_2 = FirebaseDB.PackDus2;

const PackDus = () => {
    const [toggle, setToggle] = useState(true);

    const [dataTemperatur, setDataTemperatur] = useState(0);
    const [dataKelembaban, setDataKelembaban] = useState(0);
    const [dataAirQuality, setDataAirQuality] = useState(0);

    const [dataRPD1, setDataRPD1] = useState({ hitung: 0 });
    const [dataRPD2, setDataRPD2] = useState({ hitung: 0 });
    const [targetHPD1, setTargetHPD1] = useState(0);
    const [targetHPD2, setTargetHPD2] = useState(0);
    const [dataLbPD1, setLbPD1] = useState('');
    const [dataLbPD2, setLbPD2] = useState('');
    const [dataWFPD1, setDataWFPD1] = useState({});
    const [dataWFPD2, setDataWFPD2] = useState({});

    const [dataHPD1, setDataHPD1] = useState([]);
    const [dataHPD2, setDataHPD2] = useState([]);

    const [logPackDus1, setLogPackDus1] = useState([]);
    const [logPackDus2, setLogPackDus2] = useState([]);
    const [logTemperatur, setLogTemperatur] = useState([]);
    const [logKelembaban, setLogKelembaban] = useState([]);
    const [logAirQuality, setLogAirQuality] = useState([]);

    function chageView() {
        setToggle(toggle => !toggle);
    }

    useEffect(() => {
        queryPackDus1();
        queryPackDus2();
    }, []);

    const queryPackDus1 = () => {
        const query = ref(db, pack_dus_1);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (snapshot.exists()) {
                //realtime
                setDataRPD1({ hitung: data.realtime['hitung'], timestamp: data.realtime['timestamp'] });
                setDataTemperatur(data.realtime['temperatur']);
                setDataKelembaban(data.realtime['kelembaban']);

                //history
                let history = data.history;
                var hitungTertinggi = 0;
                var packDus1Arr = [];
                Object.keys(history).map((key) => {
                    packDus1Arr.push({ data: history[key]['hitung'], label: key });

                    if (history[key]['hitung'] > hitungTertinggi) {
                        hitungTertinggi = history[key]['hitung'];
                    }
                });
                setDataHPD1(packDus1Arr.slice(-7));
                setTargetHPD1(hitungTertinggi);
                setLbPD1(data.lastboot);

                //daily
                let daily = data.daily[formatDate(dateEnd)];
                var packDus1Arr = [];
                var temperaturArr = [];
                var kelembabanArr = [];
                Object.keys(daily).map((key) => {
                    packDus1Arr.push({ data: daily[key]['hitung'], label: key });
                    temperaturArr.push({ data: daily[key]['temperatur'], label: key });
                    kelembabanArr.push({ data: daily[key]['kelembaban'], label: key });
                });
                setLogPackDus1(packDus1Arr);
                setLogTemperatur(temperaturArr);
                setLogKelembaban(kelembabanArr);

                //wifi
                let wifi = data.realtime.WIFI;
                setDataWFPD1({ ip: wifi['IP'], ssid: wifi['SSID'], signal_strength: wifi['RSSI'] });
            }
        });
    }

    const queryPackDus2 = () => {
        const query = ref(db, pack_dus_2);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (snapshot.exists()) {
                //realtime
                setDataRPD2({ hitung: data.realtime['hitung'], timestamp: data.realtime['timestamp'] });
                setDataAirQuality(data.realtime['air_quality']);

                //history
                let history = data.history;
                var hitungTertinggi = 0;
                var packDus2Arr = [];
                Object.keys(history).map((key) => {
                    packDus2Arr.push({ data: history[key]['hitung'], label: key });

                    if (history[key]['hitung'] > hitungTertinggi) {
                        hitungTertinggi = history[key]['hitung'];
                    }
                });
                setDataHPD2(packDus2Arr.slice(-7));
                setTargetHPD2(hitungTertinggi);
                setLbPD2(data.lastboot);

                //daily
                let daily = data.daily[formatDate(dateEnd)];
                var packDus2Arr = [];
                var airQualityArr = [];
                Object.keys(daily).map((key) => {
                    packDus2Arr.push({ data: daily[key]['hitung'], label: key });
                    airQualityArr.push({ data: daily[key]['air_quality'], label: key });
                });
                setLogPackDus2(packDus2Arr);
                setLogAirQuality(airQualityArr);

                //wifi
                let wifi = data.realtime.WIFI;
                setDataWFPD2({ ip: wifi['IP'], ssid: wifi['SSID'], signal_strength: wifi['RSSI'] });
            }
        });
    }

    return (
        <div class="container-fluid p-3">
            <h1 class="text-center text-dark fw-bold lh-1" style={{ letterSpacing: '3px' }}>Packing Press</h1>
            <div className="Button-Absolute" onClick={chageView}>
                <FaSyncAlt class="text-dark bg-white p-1" style={{ height: 30, width: 30 }} />
            </div>
            <div className="Header-Container">
                <GfGWeatherApp />
                <div class="text-end text-dark align-items-center row">
                    <div>Total<h1 class="fw-bold lh-1" style={{ fontSize: 70, margin: 0 }}>{commify(dataRPD1.hitung + dataRPD2.hitung)}</h1></div>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 col-sm-6 col-md-4 pb-2">
                    <TemperaturCard value={dataTemperatur} log={logTemperatur} toggleParent={toggle} />
                </div>
                <div class="col-12 col-sm-6 col-md-4 pb-2">
                    <KelembabanCard value={dataKelembaban} log={logKelembaban} toggleParent={toggle} />
                </div>
                <div class="col-12 col-sm-6 col-md-4 pb-2">
                    <KualitasUdaraCard value={dataAirQuality} log={logAirQuality} toggleParent={toggle} />
                </div>
                <div class="col-12 col-md-6 pb-2">
                    <HitungMesin title={'Hitung Mesin 2'} value={dataRPD2} wifi={dataWFPD2} target={targetHPD2} lastboot={dataLbPD2} log={logPackDus2} toggleParent={toggle} />
                </div>
                <div class="col-12 col-md-6 pb-2">
                    <HitungMesin title={'Hitung Mesin 1'} value={dataRPD1} wifi={dataWFPD1} target={targetHPD1} lastboot={dataLbPD1} log={logPackDus1} toggleParent={toggle} />
                </div>
            </div>

            <RiwayatMesin dataHPD1={dataHPD1} dataHPD2={dataHPD2} toggleParent={toggle} />
        </div>
    );
};

export default PackDus;
