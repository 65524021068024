import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import Gauge from "./Gauge";
import { FaWifi } from 'react-icons/fa';
import { convertEpoch, commify } from "../../Helper";

function VolTankCard({ data }) {
    const elementRef = useRef(null);
    const [fontSize, setFontSize] = useState(55);
    const [paddingTop, setPaddingTop] = useState(150);

    useLayoutEffect(() => {
        function handleResize() {
            if (elementRef.current) {
                const width = elementRef.current.offsetWidth;
                const height = elementRef.current.offsetHeight;

                if (width > 395) {
                    setFontSize(55);
                    setPaddingTop(150);
                }
                if (width <= 395) {
                    setFontSize(40);
                    setPaddingTop(100);
                }
                if (width <= 300) {
                    setFontSize(30);
                    setPaddingTop(60);
                }
            }
        }

        handleResize(); // initial call to get width and height of the element
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [elementRef]);

    return (
        <div class="card h-100 text-center border-light-subtle">
            <div class="d-flex flex-column justify-content-around">
                <span class="badge bg-secondary"><FaWifi /> {data['WiFi']['SSID']} {data['WiFi']['IP']} {data['WiFi']['signal_strength']}dBm</span>
            </div>
            <div class="card-body">
                <h5 class="card-title">{data['nama']}</h5>
                <div ref={elementRef} class="d-flex flex-column justify-content-around">
                    <Gauge value={data['volume']} target={data['volume_total']} />
                    <div className="Text-Absolute" style={{ fontSize: fontSize, paddingTop: paddingTop }}>
                        <span class="badge bg-warning text-dark">{commify(parseInt(data['volume']))}<br />Liter</span>
                    </div>
                    {/* <div className="Text-Absolute" style={{ top: '65%' }}><span class="badge bg-warning text-dark">Jarak {commify(parseInt(data['jarak']))}cm</span></div> */}
                    <div class="d-flex justify-content-around">
                        <h6><span class="badge bg-secondary">Data terakhir</span> {convertEpoch(data['timestamp'])}</h6>
                        <h6><span class="badge bg-secondary">Boot terakhir</span> {data['lastboot']}</h6>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default VolTankCard;
