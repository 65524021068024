import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBM7QOyDsM2d_BQUU3Zfm1RwsV91_kHTJM",
    authDomain: "sbcr-119aa.firebaseapp.com",
    databaseURL:
        "https://sbcr-119aa-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "sbcr-119aa",
    storageBucket: "sbcr-119aa.appspot.com",
    messagingSenderId: "544489719530",
    appId: "1:544489719530:web:1ee38a12248d5e19a1f993",
}

const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);
